<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/transferor">Transferor</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachment for Supporting Evidence</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation Summary</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">View Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-7">

              <h2 class="mb-3">Applicant <a href="#" data-toggle="popover" data-placement="top" data-title="What do you put here?" data-content="
                (optional) Enter instruction or information affected by this release. e.g. Merge CA23741 or Subject to CPL CA123456" v-popover><svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg></a></h2>

              <div class="mb-4 p-2 p-md-4 bg-medium">

                <!-- Application form -->
                <form action="" v-if="applicantSaved == false || applicantEditing == true">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a class="btn btn-md btn-secondary" href="javascript:void(0)">Load Applicant Profile</a>
                    </li>
                    <li class="list-inline-item">
                      <a class="d-block d-sm-inline mt-2" href="javascript:void(0)">Clear All Fields</a>
                    </li>
                  </ul>

                  <div class="row">
                    <div class="col-sm-10 col-md-9">
                      <div class="form-group">
                        <label for="name">Name of Applicant</label>
                        <input class="form-control" type="text" name="name" id="name" v-model="name">
                      </div>
                      <div class="form-group">
                        <label for="address">Address</label>
                        <input class="form-control mb-1" type="text" name="address" id="address" v-model="address">
                        <input class="form-control" type="text" name="address2" id="adddress2" v-model="address2">
                        <div class="form-text text-muted">Second line of address</div>
                      </div>
                      <div class="form-group">
                        <label for="country">Country</label>
                        <select class="form-control" id="country" v-model="country">
                          <option>Canada</option>
                          <option>United States</option>
                          <option>Mexico</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="city">City</label>
                        <input class="form-control" type="text" name="city" id="city" v-model="city">
                      </div>
                      <div class="form-row">
                        <div class="form-group col-lg-8">
                          <label for="province">Province</label>
                          <select class="form-control" name="province" id="province" v-model="province">
                            <option value="AB">Alberta</option>
                            <option value="BC">British Columbia</option>
                            <option value="MB">Manitoba</option>
                            <option value="NB">New Brunswick</option>
                            <option value="NL">Newfoundland and Labrador</option>
                            <option value="NT">Northwest Territory</option>
                            <option value="NS">Nova Scotia</option>
                            <option value="NU">Nunavut</option>
                            <option value="ON">Ontario</option>
                            <option value="PE">Prince Edward Island</option>
                            <option value="QC">Quebec</option>
                            <option value="SK">Saskatchewan</option>
                            <option value="YT">Yukon Territory</option>
                          </select>
                        </div>
                        <div class="form-group col-lg-4">
                          <label for="postal-code">Postal Code</label>
                          <input class="form-control" name="postal-code" id="postal-code" autocomplete="shipping postal-code" v-model="postalCode">
                        </div>
                      </div>
                      <div class="mt-1 form-actions">
                        <button class="btn btn-md btn-tertiary" @click.prevent="applicantSaved = true; applicantEditing = false">Save Applicant</button>
                        <button class="btn btn-md btn-link" @click.prevent="applicantEditing = false" v-if="applicantEditing == true">Cancel</button>
                      </div>
                    </div>
                  </div>
                </form>

                <!-- Applicant saved card -->
                <div class="card" v-if="applicantSaved == true && applicantEditing == false" style="width: 18rem;">
                  <div class="card-body">
                    <h4 class="card-title">{{ name }}</h4>
                    {{ address }}<br>
                    {{ address2 }}<br v-if="address2">
                    {{ city }} {{ province }} {{ postalCode }}<br>
                    {{ country }}
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-md btn-link" v-if="applicantSaved == true" @click="applicantEditing = true">Edit</button>
                  </div>
                </div>
              </div>

              <div class="custom-control custom-checkbox mb-3">
                <input id="check" type="checkbox" class="custom-control-input" checked>
                <label class="custom-control-label" for="check">Deduct LTSA Fees</label>
              </div>

              <!-- More information toggle form -->
              <form action="" class="mb-4">
                <button class="btn btn-link px-0" @click="moreInformation = true"  v-if="moreInformation == false">
                  <svg class="icon icon-add"><use xlink:href="/icons/symbol-defs.svg#icon-add"></use></svg>
                  <strong>Add more information</strong> <i>(optional)</i>
                </button>
                <span v-if="moreInformation == true"><strong>More information</strong> <i>(optional)</i></span>
                <a class="ml-1" href="#" data-toggle="popover" data-placement="top" data-title="What do you put here?" data-content="
                (optional) Enter instruction or information affected by this release. e.g. Merge CA23741 or Subject to CPL CA123456" v-popover><svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg></a>

                <div class="form-group" v-if="moreInformation == true">
                  <textarea class="form-control" name="moreInformation" id="moreInformation" rows="3" v-model="moreInfo" v-if="moreInformationAdded == false"></textarea>
                  <div v-if="moreInformationAdded == true">{{ moreInfo }}</div>
                  <a v-if="moreInformationAdded == true" href="javascript:void(0)" @click.prevent="moreInformationAdded = false">Edit</a>
                  <div class="mt-1" v-if="moreInformationAdded == false">
                    <button class="btn btn-md btn-tertiary" @click.prevent="moreInformationAdded = true" type="submit">Done</button>
                    <button class="btn btn-md btn-link" @click.prevent="moreInformation = false">Cancel</button>
                  </div>
                </div>
              </form>

              <!-- Action buttons -->
              <div class="d-flex justify-content-sm-end">
                <button class="btn btn-secondary mr-1 d-block">Save Progress</button>
                <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>
import PackageHeader from "../../../components/PackageHeader.vue";
import PackageNavigation from "../../../components/PackageNavigation.vue";
import HelpPanel from "../../../components/HelpPanel.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
    "help-panel": HelpPanel
  },

  data() {
    return {
      applicantSaved: false,
      applicantEditing: false,
      moreInformation: false,
      moreInformationAdded: false,
      name: "",
      address: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      moreInfo: ""
    };
  },
  methods: {}
};
</script>
